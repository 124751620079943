<template>
  <div class="mtravelsdate">
    <h3 class="title">{{ result.theTitle }}</h3>
    <div class="biaoo">
      <span class="time">时间: {{ result.createTime }}</span>
      <span class="browse">浏览量: {{ result.readCount }}</span>
    </div>
    <div class="desc" v-html="result.theCont"></div>
  </div>
</template>
<script>
import { getAction } from "@/api/manage";
export default {
  components: {
  },
  created() {
    this.loadData();
  },
  data() {
    return {
      result: {},
    };
  },
  methods: {
    loadData() {
      getAction("/note/tourNote/queryById", {
        id: this.$route.query.id,
      }).then((res) => {
        if (res.success) {
          this.result = res.result;
          this.crumbdata.push({
            name: res.result.theTitle,
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.mtravelsdate {
  padding: 20px 0 50px;
  .title {
    text-align: center;
    font-size: 24px;
    font-weight: normal;
    color: #333333;
  }
  .biaoo {
    margin: 20px 0;
    text-align: center;
    font-size: 14px;
    color: #999999;
    span{
      margin: 0 20px;
    }
  }
  .desc {
    padding: 0 45px 0 45px ;
    margin: 0 auto;
    color: #666666;
    text-align: justify;
    min-height: 500px;
    p {
      img {
        display: block;
        width: 90% !important;
        margin: 0 auto;
      }
    }
  }
}
</style>
